* {
  padding: 0;
  margin: 0;
}

.App {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100px 1fr;
  grid-template-areas: 
    "nav"
    "about";
}

.Content {
  background: #f9fafa;
}

.Empty {
  background: rgb(191,210,201);
  height: 100vh;
}

#profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#profile-container img {
  border: 25px solid white;
  border-bottom: 75px solid white;
  max-width: 55%;
  max-height: 55%;
}

body, html {
  font-family: 'Montserrat', sans-serif;
}

h3 {
  font-family: 'Raleway', sans-serif;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}

h4, h5 {
  text-align: center;
}

input, textarea, button {
  font-family:'Courier New', Courier, monospace;
  font-size: 18px;
  font-weight: 700;
  width: 90%;
  padding: 10px;
  color:rgb(0, 255, 42);
  background-color: rgba(0, 0, 0, 0.9);
  border: none;
  margin-bottom: 5px;
}

input:focus, textarea:focus, button:focus {
  outline: 5px solid rgb(0, 255, 42);
}

button {
  width: 94.3%;
}

button:disabled:after {
  content: "ING THANK YOU!";
}

a {
  color: black;
  text-decoration: none;
}

a:hover, i:hover {
  color: rgb(0, 255, 42);
  transition: 0.3s;
}

#header {
  height: 100px;
  box-shadow: 0 2px 18px 0 rgba(129,162,182,.2);
}

#header ul {
  display: block;
  list-style-type: none;
}

#header .menu-item {
  position: relative;
  top: 40px;
  padding-right: 25px;
  float: right;
}

#header #logo {
  position: relative;
  padding-left: 25px;
  top: 40px;
  float: left;
}

#header #logo i {
  color: black;
}

#about {
  grid-area: "about";
}

#about .error {
  color: red;
}

#about-container {
  display: flex;
  height: 700px;
  justify-content: center;
  align-items: center;
}

#about-content h3 {
  font-size: 60px;
}

#about-content h5 {
  font-size: 16px;
  text-transform: uppercase;
}

#about-content i {
  color: black;
  margin: 5px;
}

#about-content i:hover {
  color: rgb(0, 255, 42);
  transition: 0.3s;
}

#about-container .typewriter h1 {
  font-size: 16px;
}

#about-container .typewriter {
  font-family:'Courier New', Courier, monospace;
  overflow: hidden;
  white-space: nowrap;
  border-right: .5em solid rgb(0, 255, 42);
  margin: 0 auto;
  letter-spacing: .13em;
  animation:
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(9, 255, 0) }
}

#about-content form {
  text-align: center;

}

#resume-content {
  width: 500px;
}

#resume-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  font-family:'Courier New', Courier, monospace;
  margin: 0 auto;
  letter-spacing: .13em;
  font-size: 10px;
}

#projects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
}

#projects-content li {
  list-style-type: none;
  font-family:'Courier New', Courier, monospace;
  padding: 25px;
}

#projects-content ul {
  text-align: center;
}

#projects-content h3 {
  font-size: 50px;
  font-weight: 700;
}

#projects-content p {
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 800px) {
  #resume-content {
    width: 350px;
    padding: 0 10px 0 10px
  }

  #projects h3 {
    font-size: 40px;
  }

  #header {
    width: 100vw;
  }

  #about h3 {
    font-size: 40px;
  }

  #about-container .typewriter h1 {
    padding: 0 5px 0 5px;
    font-size: 11px;
    margin: 0 auto;
  }

  #about-content form {
    text-align: center;
    width: 370px;
  }

  #about textarea {
    height: 100px;
  }

  #about-content i {
    color: black;
    margin: 10px;
    /* margin: 0 50px 0 65px; */
  }

  .Empty{
    display: none;
  }
}